<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="add()"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="物业名称"
          v-model="searchParam.name"
          placeholder="请输入物业名称"
        />
        <v-input
          label="物业手机号"
          v-model="searchParam.mobile"
          placeholder="请输入物业手机号"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="edit(scope.row)" />
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form label-position="right" label-width="120px">
        <el-form-item label="用户类别" prop="authSpaceTypes">
          <div class="check-panel">
            <v-checkbox
              v-model="item.checked"
              v-for="(item, index) in userTypeOps"
              :key="index"
              :index="index"
              type="border"
              :label="item.label"
              @change="checkChange"
            />
          </div>
        </el-form-item>
        <el-form-item
          v-show="
            form.authSpaceTypes !== undefined && form.authSpaceTypes !== ''
          "
          label="区域"
          prop="authSpaceIds"
        >
          <v-cascader
            placeholder="默认表示全部"
            v-model="authSpaceIds"
            :options="sectionOps"
            :props="props"
          />
        </el-form-item>
        <el-form-item label="物业公司名称：">
          <v-input
            placeholder="请输入姓名"
            clearable
            v-model="form.name"
          ></v-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <div class="form-item">
            <v-input
              :disabled="isEdit"
              placeholder="请输入手机号"
              clearable
              v-model="form.mobile"
              @blur="handelInputBlur"
            ></v-input>
            <v-button
              text="编辑"
              @click="handelClick"
              v-if="dialogTitle == '编辑'"
              style="margin-left: 10px"
            ></v-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button size="medium" @click="back()" text="返回"></v-button>
        <v-button size="medium" @click="submit()" text="保存"></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message, MessageBox } from "element-ui";
import {
  getPropertyList,
  delPropertyInfoById,
  savePropertyInfo,
  getPropertyInfoById,
  getBuildListURL,
  propertyInfoEdit,
} from "./api.js";
import { setUserTypeOps } from "./map.js";
import { setAESCbcEncrypt } from "@/utils/common";
export default {
  name: "propertyCompanyManagement",
  data() {
    return {
      userTypeOps: setUserTypeOps(),
      dialogTitle: "",
      dialogFormVisible: false,
      tableUrl: getPropertyList,
      searchParam: {
        name: null,
        mobile: null,
      },
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      form: {
        id: "",
        name: "",
        mobile: "",
        authSpaceIds: "", // 区域id集合
        authSpaceTypes: undefined, // 用户类别id
      },
      residentsOps: [], // 用户
      merchantsOps: [], // 商圈
      allOps: [], // 用户+商圈
      spacePathIds: [], // 添加treeIds的数据
      sectionOps: [], // 用于显示的数据
      authSpaceIds: [],
      headers: [
        {
          prop: "communityName",
          label: "所属小区",
        },
        {
          prop: "name",
          label: "物业名称",
        },
        {
          prop: "mobile",
          label: "物业手机号码",
        },
        {
          prop: "evaluateScore",
          label: "物业评分",
        },
      ],
      isEdit: false,
    };
  },
  computed: {
    authSpaceTypes() {
      return this.form.authSpaceTypes;
    },
  },
  watch: {
    // 选中的用户类别集合
    userTypeOps: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.authSpaceTypes =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    authSpaceTypes() {
      let types =
        this.form.authSpaceTypes !== undefined
          ? this.form.authSpaceTypes.split(",")
          : [];
      let result = [];
      if (types.includes("11") && types.includes("10")) {
        result = [...this.allOps];
      } else if (types.includes("11")) {
        result.push(...this.merchantsOps);
      } else if (types.includes("10")) {
        result.push(...this.residentsOps);
      }
      this.sectionOps = result;
    },
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.authSpaceIds = val.map((a) => a[a.length - 1]).join(",");
      },
      deep: true,
    },
  },
  async mounted() {
    // 10 居民 11 商户
    await Promise.all([
      this.getBuildList(10),
      this.getBuildList(11),
      this.getBuildList("10,11"),
    ]);
  },
  methods: {
    //编辑手机号
    handelInputBlur() {
      if (this.dialogTitle == "编辑") {
        const params = {
          id: this.form.id,
          mobile: setAESCbcEncrypt(this.form.mobile),
        };
        this.$axios.post(propertyInfoEdit, params).then((res) => {
          if (res.code == 200) {
            this.isEdit = true;
            this.$message.success(res.msg);
          }
        });
      }
    },
    handelClick() {
      this.form.mobile=""
      this.isEdit = false;
    },
    add() {
      this.dialogTitle = "新增";
      this.isEdit = false;
      this.dialogFormVisible = true;
      this.form.name = "";
      this.form.mobile = "";
      this.form.authSpaceIds = "";
      this.form.authSpaceTypes = undefined;
      this.userTypeOps.forEach((item) => {
        item.checked = false;
      });
    },
    edit(data) {
      console.log(data);
      this.dialogTitle = "编辑";
      this.isEdit = true;
      this.dialogFormVisible = true;
      this.form.id = data.id;
      this.form.mobile = data.mobile;
      this.form.name = data.name;
      this.authSpaceIds = [];
      this.form.authSpaceIds = data.authSpaceIds;
      this.form.authSpaceTypes = data.authSpaceTypes;
      let types = data.authSpaceTypes.split(",");
      this.userTypeOps.forEach((v) => {
        if (types.includes(v.value)) {
          v.checked = true;
        } else {
          v.checked = false;
        }
      });
      const ids = this.form.authSpaceIds
        ? this.form.authSpaceIds.split(",")
        : [];
      this.spacePathIds.forEach((item) => {
        if (ids.includes(item.id)) {
          this.authSpaceIds.push([...item.treeIds]);
        }
      });
      console.log(this.authSpaceIds);
    },
    remove(data) {
      //删除
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: data.id,
          };
          this.$axios
            .post(`${delPropertyInfoById}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    async getBuildList(type) {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=${type}`
      );
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data);
        console.log(res.data, opt, "opt---");
        // 10 用户 11 商圈
        if (type === 10) {
          this.residentsOps = opt;
        } else if (type === 11) {
          this.merchantsOps = opt;
        } else {
          this.allOps = opt;
        }
      }
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      console.log(data);
      return data;
    },
    back() {
      this.dialogFormVisible = false;
    },
    submit() {
      if (!this.form.name || this.form.name == "") {
        this.$message.error("请输入姓名！");
        return;
      }
      if (!this.form.mobile || this.form.mobile == "") {
        this.$message.error("请输入手机号！");
        return;
      }
      if (this.form.authSpaceTypes == "") {
        this.$message.error("请选择用户类别！");
        return;
      }
      if (this.form.authSpaceIds.length == 0) {
        this.$message.error("请选择区域！");
        return;
      }
      if (this.dialogTitle == "编辑") {
        let params = {
          id: this.form.id,
          createUser: this.$store.state.app.userInfo.userId,
          name: this.form.name,
          // mobile: this.form.mobile,
          authSpaceTypes: this.form.authSpaceTypes,
          authSpaceIds: this.form.authSpaceIds,
        };
        this.$axios.post(`${savePropertyInfo}`, params).then((res) => {
          if (res.code === 200) {
            this.dialogFormVisible = false;
            this.$message.success(res.msg);
            this.$refs.list.search();
          }
        });
      } else {
        let params = {
          createUser: this.$store.state.app.userInfo.userId,
          name: this.form.name,
          mobile: setAESCbcEncrypt(this.form.mobile),
          authSpaceTypes: this.form.authSpaceTypes,
          authSpaceIds: this.form.authSpaceIds,
        };
        this.$axios.post(`${savePropertyInfo}`, params).then((res) => {
          if (res.code === 200) {
            this.dialogFormVisible = false;
            this.$message.success(res.msg);
            this.$refs.list.search();
          }
        });
      }
    },
    checkChange() {
      // 每次改变时, 清空默认选择项
      this.authSpaceIds = [];
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
  .form-item {
    display: flex;
  }
  .check-panel {
    display: flex;
  }
}
</style>
